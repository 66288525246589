import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Band Pull Aparts 3×12`}</p>
    <p>{`Rack Slides 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`OHS’s 4×6\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each:`}</p>
    <p>{`Hang Power Snatch (95/65)(RX+ 115/75)`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      